import React, { useEffect } from "react";
import "./Contact.css";
import Form from "../Form/Form";
import ContactCard from "./ContactCard";
import DirectorsData from "./DirectorsData";
import ScrollReveal from "scrollreveal";

const Contact = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: "right",
      distance: "80px",
      duration: 2000,
      reset: true,
    });

    sr.reveal(".Contact-box-right", {});

    return () => sr.destroy();
  }, []);

  useEffect(() => {
    const sr = ScrollReveal({
      origin: "left",
      distance: "80px",
      duration: 2000,
      reset: true,
    });

    sr.reveal(".Contact-box-left", {});

    return () => sr.destroy();
  }, []);

  return (
    <div className="Contact">
      <div className="Contact-main">
        <div className="Contact-box">
          <div className="Contact-box-left">
            <div className="contact-card">
              <div className="contact-card-box">
                <div className="about-title">
                  <span>Contact Us</span>
                  <h2>Contact Us</h2>
                </div>
                <div className="contact-detail">
                  <p>
                    For premier import-export services, connect with HARBOURSYNC
                    EXPIMP PRIVATE LIMITED by filling out the form provided or
                    by calling the listed phone number. We're here to streamline
                    your global trade.
                  </p>
                </div>
              </div>
            </div>
            {/* {DirectorsData.map((item) => (
              <ContactCard item={item} key={item.id}/>
            ))} */}
          </div>
          <div className="Contact-box-right">
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
